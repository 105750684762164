import { Close } from '@mui/icons-material'
import { Dialog, DialogTitle, Grid, IconButton, Paper } from '@mui/material'
import numeral from 'numeral'
import { useState, useMemo, useEffect } from 'react'
import { Alert } from 'reactstrap'

import { isSameYear } from '../../../../_helpers/date'
import { isMyga, isVega, isDenali, isRiderEvolve } from '../../../../_helpers/policy'
import { getPolicyValues } from '../../../../_services/policyService'
import { PaperDisplay } from '../../../_components/PaperDisplay'
import { useStore } from '../../../_hooks/use-store'
import './AccountValues.css'
import CashSurrenderBreakdown from './CashSurrenderBreakdown'
import CashSurrenderValueDetails from './CashSurrenderValueDetails'
import ContractDetails from './ContractDetails'
import IncomeValueCard from './IncomeValue/IncomeValueCard'
import InterestBreakdown from './InterestBreakdown'
import PremiumD3Chart from './PremiumD3Chart'
import { IPolicyValues } from './types'
import { EModal } from './EModal'

function AccountValues() {
  const {
    store: { selectedPolicy },
  } = useStore()
  const [openModals, setOpenModals] = useState(EModal.None)
  const [policyValues, setPolicyValues] = useState<null | IPolicyValues>(null)

  const policyNumber = selectedPolicy.data.application.policy_number
  const _isSameYear = isSameYear(selectedPolicy.created_at)

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await getPolicyValues(policyNumber)
        setPolicyValues(data)
      } catch (e) { }
    }
    loadData()
  }, [policyNumber])

  const { _isMyga, _isVega, _isDenali, _isRiderEvolve } = useMemo(
    () => ({
      _isMyga: isMyga(selectedPolicy),
      _isVega: isVega(selectedPolicy),
      _isDenali: isDenali(selectedPolicy?.product.id),
      _isRiderEvolve: isRiderEvolve(selectedPolicy),
    }),
    [selectedPolicy]
  )

  const toggleInterestDetailsModal = () => {
    setOpenModals(
      openModals === EModal.InterestDetails
        ? EModal.None
        : EModal.InterestDetails
    )
  }

  const toggleCashSurrenderModal = () => {
    setOpenModals(
      openModals === EModal.CashSurrender ? EModal.None : EModal.CashSurrender
    )
  }

  if (!selectedPolicy?.account_value)
    return (
      <Alert color="warning">
        Account Values are currently unavailable. Policy has not been issued.
      </Alert>
    )

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={12} container spacing={2}>
          <Grid item sm={12} md={4} lg={!_isMyga}>
            <PaperDisplay
              displayId="AccountValue"
              toolTipPlacement="right"
              displayTitle="Account Value"
              displayValue={numeral(
                selectedPolicy?.account_value?.total
              ).format('$0,0.00')}
              toolTipText="This value may change daily depending on your crediting strategy allocation."
            />
          </Grid>
          {_isDenali && _isRiderEvolve && (
            <Grid item sm={12} md={4} lg={!_isMyga}>
              <IncomeValueCard />
            </Grid>
          )}
          {_isVega && (
            <Grid item sm={12} md={4} lg={!_isMyga}>
              <PaperDisplay
                displayId="BenefitValue"
                displayTitle="Benefit Value"
                displayValue={numeral(
                  selectedPolicy?.account_value?.benefit_value
                ).format('$0,0.00')}
              />
            </Grid>
          )}
          {/* {!_isMyga && ( */}
          <Grid item sm={12} md={4} lg={!_isMyga}>
            <PaperDisplay
              displayId="CashSurrenderValue"
              toolTipPlacement="right"
              displayTitle="Cash Surrender Value"
              displayValue={numeral(
                selectedPolicy?.account_value?.cash_value
              ).format('$0,0.00')}
              toolTipText="The amount available if you were to terminate your policy. It can fluctuate with the MVA."
            />
          </Grid>
          {/* )} */}
          <Grid item sm={12} md={4} lg={!_isMyga}>
            <PaperDisplay
              displayId="YtdInterestCredits"
              toolTipPlacement="right"
              displayTitle="Interest Credits"
              displayValue={numeral(
                selectedPolicy?.account_value?.interest
              ).format('$0,0.00')}
              toolTipText="Total interest credited since first premium received."
              footerAction={
                _isSameYear && _isMyga
                  ? undefined
                  : {
                    actionText: 'View Details',
                    action: toggleInterestDetailsModal,
                  }
              }
              isLoading={!Boolean(policyValues)}
            />
          </Grid>
        </Grid>

        {selectedPolicy?.account_value ||
          selectedPolicy?.account_value?.premiums !== 0 ? (
          <Grid item sm={12} container spacing={2}>
            <Grid item sm={12} md={4}>
              <Paper
                elevation={3}
                style={{
                  padding: '10px 10px 30px 10px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                <h5 className="m-b-20 text-muted">Account Value Details</h5>
                <hr />
                <br />
                <PremiumD3Chart accountValues={selectedPolicy?.account_value} />
              </Paper>
            </Grid>
            {/* {!_isMyga && ( */}
            <Grid item sm={12} md={4}>
              <Paper
                elevation={3}
                className="account-value__cash-surrender-value-container"
              >
                <div>
                  <h5 className="m-b-20 text-muted">
                    Cash Surrender Value Details
                  </h5>
                  <hr />
                  <CashSurrenderValueDetails
                    accountValues={selectedPolicy?.account_value}
                    onViewDetails={toggleCashSurrenderModal}
                  />
                </div>
                <p className="card-text card-calculation-footer">
                  <button
                    className="float-right link-button"
                    onClick={toggleCashSurrenderModal}
                  >
                    view calculations
                  </button>
                </p>
              </Paper>
            </Grid>
            {/* )} */}
            <Grid item sm={12} md={4}>
              <Paper
                elevation={3}
                style={{ padding: '10px 10px 30px 10px', height: '100%' }}
              >
                <h5 className="m-b-20 text-muted">Contract Details</h5>
                <hr />
                <ContractDetails isMyga={_isMyga} />
              </Paper>
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openModals === EModal.InterestDetails}
        title="Interest Details"
        onClose={toggleInterestDetailsModal}
        style={{ padding: '10px' }}
      >
        <div className="dialog-title">
          <DialogTitle className="dialog-title">Interest Details</DialogTitle>
          <IconButton>
            <Close onClick={toggleInterestDetailsModal} />
          </IconButton>
        </div>
        <InterestBreakdown policyValues={policyValues} isMyga={_isMyga} />
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openModals === EModal.CashSurrender}
        title="Cash Surrender Value Details"
        onClose={toggleCashSurrenderModal}
        style={{ padding: '10px' }}
      >
        <div className="dialog-title">
          <DialogTitle className="dialog-title">
            Cash Surrender Value Details
          </DialogTitle>
          <IconButton>
            <Close onClick={toggleCashSurrenderModal} />
          </IconButton>
        </div>
        <CashSurrenderBreakdown accountValues={selectedPolicy?.account_value} />
      </Dialog>

    </>
  )
}

export default AccountValues
